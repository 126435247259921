<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','goods_logo','goods_put']"
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加设备</a-button>
			</template>
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in tracing_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="operation" slot-scope="data">
				<a  @click="goods_edit(data.record)">产品配置</a>
				<a-divider type="vertical" />
				<span>
					<a v-auth="'change'" @click="edit(data.record)">编辑</a>
				</span>
				<a-divider type="vertical" />
				<span>
					<a v-auth="'change'" @click="changeStatusFun(data.record)">{{data.record.status==1?'禁用':'启用'}}</a>
				</span>
				<!-- <a-divider type="vertical" /> -->
				<span>
					<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
						<a>删除</a>
					</a-popconfirm>
				</span>
			</template>
			<template slot="goods_put" slot-scope="data">
				<span v-if="data.text=='1'">是</span>
				<span v-else-if="data.text=='0'">否</span>
			</template>

		</TableList>


		<EditPop @handleSubmit="submit" @get_select_value_tr='handleChange' :form_data="form_data" :visible.sync="visible">

		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import codeList from "@/components/codeList";
	import QRCode from 'qrcodejs2'

	import {
		getFactorySelect,
		getDeviceList,
		getWarehouseSelect,
		getLinseSelect,
		getStatusSelect,
		save,
		getTopCount,
		changeStatus,
		deleteMachine
	} from "@/api/traceability";

	let columns = [{
			title: "序号",
			dataIndex: "id",
		},

		{
			title: "设备名称",
			dataIndex: "name",
		},
		{
			title: "设备编号",
			dataIndex: "machine_code",
		},
		{
			title: "基地",
			dataIndex: "factory_sap_name",
		},
		{
			title: "产线描述",
			dataIndex: "line_name",
		},

		{
			title: "添加时间",
			dataIndex: "create_time",

		},
		{
			title: "设备状态",
			dataIndex: "status_name",
		},



		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			EditPop,
			Form,
			codeList,
			TableList,
		},
		data() {
			return {
				tracing_count: [],
				list_visible: false,
				get_table_list: getDeviceList,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				visible: false,
				form_data: {
					title: '添加',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},
						{
							type: 'text',
							name: 'name',
							title: '关键字',
							options: {
								rules: [{
									required: true,
									message: "请输入设备名称/设备编号"
								}]
							},
						},
						{
							type: 'text',
							name: 'machine_code',
							title: '设备编号',
							options: {
								rules: [{
									required: true,
									message: "请输入设备编号"
								}]
							},
						},
						{
							type: "select",
							name: "factory_sap_id",
							title: "基地",
							options: {
								rules: [{
									required: true,
									message: "请选择基地"
								}]
							},
							list:[],
							treeData: [],
							multiple: true
						},
						{
							type: "select",
							name: "line_id",
							title: "产线",
						options: {
							rules: [{
								required: true,
								message: "请选择产线"
							}]
						},
							treeData: [],
							multiple: true
						},
						{
							type: "select",
							name: "warehouse_id",
							title: "仓库",
							list:[],
							options: {
								rules: [{
									required: true,
									message: "请选择仓库"
								}]
							},
						},
						
					]
				},
				form_data_seo: {
					list: [{
							type: "text",
							name: "name",
							title: "设备名称",
							placeholder: '设备名称',
							options: {

							}
						},
						{
							type: "text",
							name: "line_name",
							title: "产线名称",
							placeholder: '产线名称',
							options: {

							},
							
						},
						{
							type: "text",
							name: "factory_name",
							title: "基地名称",
							options: {

							},
							treeData: [],
						},

						{
							type: 'select',
							name: 'status',
							title: '状态',
							list: [],
							options: {},
						},
					],
					...this.$config.form_data_seo
				},
				submit_preprocessing: {
					array_to_string: ['cate_id', 'department_ids', 'department_ids2']
				}
			};
		},
		async created() {
			this.get_option()
			this.get_Statistics_Top()

		},
		methods: {
			goods_edit(item){
				this.$router.push({
					path:"/traceability/goods_setting",
					query:{
						id:item.id,
					}
				});
			},
			handleChange(e){
				if(e.label=="factory_sap_id"){
					this.get_new_option({factory_sap_id:e.value})
				}
			},
			changeStatus(data) {
				this.get_Statistics_Top(data)
			},
			get_Statistics_Top(data) {
				getTopCount({
					data: {
						...data
					}
				}).then(res => {
					this.tracing_count = res.data.list
				})
			},
			edit(data) {
				this.form_data.list.forEach(item => {

					if (item.name == 'name') {
						item.options.initialValue = data.name;
					}
					if (item.name == 'warehouse_id') {
						item.options.initialValue = data.warehouse_id;
					}
					if (item.name == 'line_id') {
						item.options.initialValue = data.line_id;
					}
					if (item.name == 'id') {
						item.options.initialValue = data.id;
					}

					if (item.name == 'machine_code') {
						item.options.initialValue = data.machine_code;
					}
					if (item.name == 'factory_sap_id') {
						item.options.initialValue = data.factory_sap_id;
					}
				})

				this.form_data.title = "编辑设备"
				this.visible = true;

			},
			del(data) {
				// 删除
				deleteMachine({
					data: {
						id: data.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			// 
			changeStatusFun(data) {
				// 修改状态
				changeStatus({
					data: {
						id: data.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			get_option() {
				// 仓库数据
				this.get_new_option()
				// 状态数据
				getStatusSelect().then(res => {
					this.$method.set_form_list(this.form_data_seo.list, 'status', 'list', res.data.list);
				})

				// 基地
				getFactorySelect().then(res => {
					this.$method.set_form_list(this.form_data.list, 'factory_sap_id', 'list', res.data.list);
					this.$method.set_form_list(this.form_data_seo.list, 'factory_sap_id', 'list', res.data.list);
				})
			},
			get_new_option(e) {
				// 仓库数据
				getWarehouseSelect({data:{...e}}).then(res => {
					this.form_data.list.forEach(item => {
						if (item.name == 'warehouse_id') {
							item.options.initialValue =null;
						}
					})
					this.$method.set_form_list(this.form_data.list, 'warehouse_id', 'list', res.data.list);
					this.$forceUpdate()
				})
				
				// 产线数据
				getLinseSelect({data:{...e}}).then(res => {
					this.$method.set_form_list(this.form_data.list, 'line_id', 'list', res.data.list);
				})
			
			},
			add() {
				this.form_data.title = "添加设备"
				this.visible = true;
			},
			submit(e) {
				let data = e.values;

				save({
					data: {
						...data
					},
					info: true,
				}).then(res => {
					this.visible = false;
					this.$refs.list.get_list();
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../../assets/less/app.less");

	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.qrCodeUrlImg {
		margin-left: 110px;
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}
	}
</style>